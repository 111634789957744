import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Stack } from "react-bootstrap"
import { DOCS_OPEN_ACCOUNT } from "config/initial-state";
import StatusUpload from "components/molecules/status-bar/upload";
import { _HOST } from "config/constant/endpoints";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";

const FormDataKeuangan = () => {
  const { onConnect } = useSocket();
    const session = localStorage.getItem("pickup")!.split(",");
    const [data, setData] = useState([32, "","","","","", "", "", "","","", "", "","","","","", false])
    useEffect(()=>{
      const socket = io(_HOST);
  socket.on("connect", onConnect);
  socket.emit("call", "join", { room: session[1] });
  socket.on("conferencing", (...arg: any) => {
    console.log(arg, "data")
    if(arg[0] == 32){
      console.log("sebelum", data)
      setData((curr) => arg)
      console.log("sesudah", data)
      localStorage.setItem("data-keuangan",JSON.stringify(arg))
    }
  })
  return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }
  
  ,[])
    return (
    <Container>
      <Stack gap={3} className={styles.forms}>
        <StatusUpload status={DOCS_OPEN_ACCOUNT} />

        <Row>
            <Col>
            <Row>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Jenis Pekerjaan </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="jenisPekerjaan"
                      value={data[1].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Penghasilan/Bulan </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="penghasilanBulanan"
                      value={data[2].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Sumber Dana </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="sumberDana"
                      value={data[3].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Tujuan Penggunaan </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="tujuanPenggunaan"
                      value={data[4].toString()}

                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Penghasilan tambahan </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="penghasilanTambahan"
                      value={data[5].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Jenis Rekening </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="jenisRekening"
                      value={data[6].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Nama Produk </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="namaProduk"
                      value={data[7].toString()}
                        
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Mata Uang </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="mataUang"
                      value={data[8].toString()}

                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Transaksi Setor Tunai </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="transaksiSetorTunai"
                      value={data[9].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Transaksi Setor Non-Tunai </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="transaksiSetorNonTunai"
                      value={data[10].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Transaksi Penarikan Tunai </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="transaksiPenarikanTunai"
                      value={data[11].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>
                         Transaksi Penarikan Non-Tunai 
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="transaksiPenarikanNonTunai"
                      value={data[12].toString()}

                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Freq Setoran Tunai </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="frekuensiSetoranTunai"
                      value={data[13].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Freq Setoran Non-Tunai </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="frekuensiSetoranNonTunai"
                      value={data[14].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Freq Penarikan Tunai </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="frekuensiPenarikanTunai"
                      value={data[15].toString()}

                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label> Freq Penarikan Non-Tunai </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="frekuensiPenarikanNonTunai"
                      value={data[16].toString()}

                      />
                    </Form.Group>
                  </Row>
            </Col>
        </Row>
      </Stack>
        
    </Container>
    )
}

export default FormDataKeuangan