import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import { endAndHome } from "components/molecules/navbar";
import Tabbar from "components/molecules/tab";
import { _HOST } from "config/constant/endpoints";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";

const services = [
  { label: "Pembukaan Akun", img: "https://bit.ly/3LqncTB" },
  { label: "Formulir Transfer", img: "https://bit.ly/3Mvp2mj" },
  { label: "Komplain Nasabah", img: "https://bit.ly/3LqncTB" },
  { label: "Transaksi Antar Bank", img: "https://bit.ly/3Mvp2mj" },
  { label: "Perbarui Data", img: "https://bit.ly/3LqncTB"},
  { label: "Aktivasi Dormant", img: "https://bit.ly/3Mvp2mj" },
  { label: "Transaksi Valas", img: "https://bit.ly/3LqncTB" },
  { label: "Kartu ATM", img: "https://bit.ly/3Mvp2mj" },
  { label: "Surat Kuasa", img: "https://bit.ly/3LqncTB" },
  { label: "Aktivasi IBMB", img: "https://bit.ly/3Mvp2mj" },
  { label: "Formulir Kredit", img: "https://bit.ly/3LqncTB" },
  { label: "Daftar Sumber Kekayaan", img: "https://bit.ly/3Mvp2mj" },
  { label: "Profil Resiko Kekayaan", img: "https://bit.ly/3LqncTB" },
  { label: "Rencana Keuangan", img: "https://bit.ly/3Mvp2mj" },
  { label: "Kekayaan", img: "https://bit.ly/3LqncTB" },
  { label: "Transaksi Tanpa Kartu", img: "https://bit.ly/3Mvp2mj" },
];

const initialTab = [
  { label: "Portofolio", isActive: false },
  { label: "Layanan", isActive: true },
];

const Services = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  const left = services.map((item, id) => {
    if (id < services.length / 2) {
      return (
        <button key={id}>
          {item.label} <img src={item.img} alt="product image" />
        </button>
      );
    }
  });

  const right = services.map((item, id) => {
    if (id > (services.length - 1) / 2) {
      return (
        <button key={id}>
          {item.label} <img src={item.img} alt="product image" />
        </button>
      );
    }
  });

  return (
    <div className={styles.services}>
      <Tabbar tabs={initialTab} />
      <h3>Daftar Layanan</h3>
      <div>
        <div>{left}</div>
        <div>{right}</div>
      </div>
    </div>
  );
};

export default Services;
