import { useState } from "react";
import Chart from "react-apexcharts";

const DonutChart = (props: { isRegistered: boolean }) => {
  const { isRegistered } = props;
  const [series, setSeries] = useState([
    30000000, 20000000, 10000000, 40000000,
  ]);
  const [labels, setLabels] = useState([
    "Giro",
    "Tabungan",
    "Deposito",
    "Bancassurance",
  ]);
  const [options, setOptions] = useState({
    labels: labels,
    colors: ["#2dcc70", "#f1c40f", "#e94a3e", "#3598db"],
  });

  if (isRegistered) {
    return (
      <div>
        <Chart type="donut" options={options} series={series} width="380" />
      </div>
    );
  } else {
    return (
      <div style={{ display: "block" }}>
        <Chart
          width="480"
          type="donut"
          series={[1]}
          options={{
            labels: [
              `Tabungan`,
            ],
            colors: ["#22D882"],
            dataLabels: {
              enabled: true,
              formatter: function (val: number) {
                return "100%";
              },
            },
          }}
        />
      </div>
    );
  }
};

export default DonutChart;
