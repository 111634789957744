import { useEffect } from "react";
import styles from "./index.module.scss";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import { useDispatch } from "react-redux";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import { _HOST } from "config/constant/endpoints";
import { endAndHome } from "components/molecules/navbar";

const SuccesActivation = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      if (arg[0] === 13 && isProceed) {
        dispatch(proceed(11));
      }
      if (arg[0] === 14 && isProceed) {
        dispatch(proceed(11));
      }
      if (arg[0] === 20 && isProceed) {
        dispatch(proceed(11));
      }
      if (arg[0] === 99) {
        endAndHome(JSON.parse(localStorage.getItem("zoom-client")!));
      }
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <div className={styles.successAct}>
      <div>
        <h1>Selamat!</h1>
        <p>Aktivasi Berhasil</p>
        <p>Rekening tabungan Anda sudah aktif</p>
        <ul>
          <li>
            <span>Nama</span>
            <span>: {JSON.parse(localStorage.getItem("cif-data-diri")!)[1]}</span>
          </li>
          <li>
            <span>No Rekening</span>
            <span>: 123456789</span>
          </li>
          <li>
            <span>Kantor Cabang</span>
            <span>: DiPS KCP Depok</span>
          </li>
        </ul>
        <p>
          <i>
            Mohon aktifkan rekening Anda dengan menyertakan dana, maksimal 90
            hari kedepan
          </i>
        </p>
        <p>
          Terima kasih telah mempercayakan kegiatan perbankan Anda bersama kami.
          Detail informasi akun rekening Anda telah dikirim melalui email.
        </p>
      </div>
    </div>
  );
};

export default SuccesActivation;
