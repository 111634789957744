
import  Row  from "react-bootstrap/Row";
import  Card  from "react-bootstrap/Card";
import  Form  from "react-bootstrap/Form";
import  Container  from "react-bootstrap/Form";
import  Col  from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { _HOST } from "config/constant/endpoints";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { Stack } from "react-bootstrap";

const FormKredit = () => {
    const { onConnect } = useSocket();
    const session = localStorage.getItem("pickup")!.split(",");
    const [data, setData] = useState([441,	"nama",	"nik",	"nomer hp","email", "alamat","agama","status", "tenor","20000",	false,	false,	false])
    useEffect(()=>{
        const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg, "data")
      if(arg[0] === 441){
        console.log("sebelum", data)
        setData((curr) => arg)
        console.log("sesudah", data)

      }
    })
    return () => {
        socket.off("connect");
        socket.off("conferencing");
        socket.close();
      };
    }
    
    ,[])
    return (
        <Row className="justify-content-md-center">
            <Col sm={6}>
                <Card.Title className="text-center">Formulir Kredit</Card.Title>
                <Card.Body>
                <Stack gap={2}>
                <Row>
                        <Form.Group>
                            <Form.Label>Nama</Form.Label>
                            <Form.Control value={data[1].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>NIK</Form.Label>
                            <Form.Control value={data[2].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Nomer HP</Form.Label>
                            <Form.Control value={data[3].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control value={data[4].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Alamat</Form.Label>
                            <Form.Control as="textarea" rows={3} value={data[5].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Agama</Form.Label>
                            <Form.Control value={data[6].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Form.Control value={data[7].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Tenor</Form.Label>
                            <Form.Control value={data[8].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Nominal</Form.Label>
                            <Form.Control value={`Rp. ${Number(data[9]).toLocaleString("id")}`}/>
                        </Form.Group>
                    </Row>
                    
                </Stack>
                    <Row>
                        <Form.Group>
                            <Form.Check label= "Saya menyatakan bahwa seluruh data yang saya berikan di formulir perbaharui data ini benar." checked={data[10] === true}/>
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
        
    )
}

export default FormKredit