import { _HOST } from "config/constant/endpoints";
import { createElement, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { FaPlus } from "react-icons/fa";
import { endAndHome } from "components/molecules/navbar";
interface sumberDanaInterface {
  line1: string;
  line2: string;
  line3: string;
}
interface halaman {
  page: number;
  totalPage: number;
}
const FormRTGS = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isFile, setFile] = useState<string>("");
  const { onConnect } = useSocket();
  const [sumberdana, setSumberdana] = useState<sumberDanaInterface>({
    line1: "",
    line2: "",
    line3: "",
  });
  const [halaman, setHalaman] = useState<halaman>({ page: 1, totalPage: 1 });
  const session = localStorage.getItem("pickup")!.split(",");
  const bankRef = useRef<HTMLInputElement>(null);
  const accountRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const serviceRef = useRef<HTMLInputElement>(null);
  const toRef = useRef<HTMLInputElement>(null);
  const civilizationRef = useRef<HTMLInputElement>(null);
  const newsRef = useRef<HTMLInputElement>(null);
  const alamatRef = useRef<HTMLInputElement>(null);
  // const pageRef = useRef<HTMLInputElement>(null);
  // const allRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      if (bankRef && arg.length === 17) {
        bankRef.current!.value = arg[5];
        accountRef.current!.value = arg[6];
        nameRef.current!.value = arg[7];
        alamatRef.current!.value = arg[16];
        amountRef.current!.value = `Rp. ${arg[8]}`;
        serviceRef.current!.value = arg[9];
        toRef.current!.value = arg[10];
        civilizationRef.current!.value = arg[11];
        newsRef.current!.value = arg[12];
        setHalaman({
          page: parseInt(arg[13] ? arg[13] : "1"),
          totalPage: parseInt(arg[14] ? arg[14] : "1"),
        });
      }

      if (arg[2] && arg[3] && arg[4]) {
        setSumberdana({
          line1: arg[2],
          line2: arg[3],
          line3: arg[4],
        });
      }
      if (arg[1] !== "") {
        setFile((curr) => arg[1]);
      }

      // if (arg[0] === 17 && isProceed) {
      //   dispatch(proceed(14));
      // }
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.upload}>
          <label htmlFor="">Unggah File</label>
          <div>{isFile === "" ? createElement(FaPlus) : <p>{isFile}</p>}</div>
        </div>
        <div className={styles.source}>
          <label htmlFor="">Rekening Sumber Dana</label>
          <div>
            <p>{sumberdana.line1}</p>
            <p>{sumberdana.line2}</p>
            <p>{sumberdana.line3}</p>
          </div>
        </div>
        <div className={styles.single}>
          <label htmlFor="">Bank Penerima</label>
          <input type="text" ref={bankRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Rekening Penerima</label>
          <input type="text" ref={accountRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Nama Penerima</label>
          <input type="text" ref={nameRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Alamat Penerima</label>
          <input type="text" ref={alamatRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Nominal Transaksi</label>
          <input type="text" ref={amountRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Jenis Layanan</label>
          <input type="text" ref={serviceRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Penerima Manfaat</label>
          <input type="text" ref={toRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Jenis Penduduk</label>
          <input type="text" ref={civilizationRef} disabled />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Jenis Berita</label>
          <input type="text" ref={newsRef} disabled />
        </div>
        <h4>
          halaman {halaman.page ? halaman.page : "1"} dari{" "}
          {halaman.totalPage ? halaman.totalPage : "1"}
        </h4>
      </div>
    </div>
  );
};

export default FormRTGS;
