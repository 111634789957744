
import  Row  from "react-bootstrap/Row";
import  Card  from "react-bootstrap/Card";
import  Form  from "react-bootstrap/Form";
import  Container  from "react-bootstrap/Form";
import  Col  from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { _HOST } from "config/constant/endpoints";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";

const FormKomplain = () => {
    const { onConnect } = useSocket();
    const session = localStorage.getItem("pickup")!.split(",");
    const [data, setData] = useState([351,	"perihal",	"tanggal",	"detail komplain",	false,	false,	false])
    useEffect(()=>{
        const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg, "data")
      if(arg[0] === 351){
        console.log("sebelum", data)
        setData((curr) => arg)
        console.log("sesudah", data)

      }
    })
    return () => {
        socket.off("connect");
        socket.off("conferencing");
        socket.close();
      };
    }
    
    ,[])
    return (
        <Row className="justify-content-md-center">
            <Col sm={6}>
                <Card.Title className="text-center">Formulir Komplain</Card.Title>
                <Card.Body>
                    <Row>
                        <Form.Group>
                            <Form.Label>Perihal</Form.Label>
                            <Form.Control value={data[1].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Tanggal</Form.Label>
                            <Form.Control value={data[2].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label>Detail Komplain</Form.Label>
                            <Form.Control as="textarea" rows={3} value={data[3].toString()}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Check label= "Saya menyatakan bahwa seluruh data yang saya berikan di formulir pembukaan rekening ini benar." checked={data[4] === true}/>
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
        
    )
}

export default FormKomplain