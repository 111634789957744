import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import { _HOST } from "config/constant/endpoints";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { endAndHome } from "components/molecules/navbar";

const TnC = () => {
  const imgBtnLink = "https://bit.ly/3xWiGbM";
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  return (
    <div>
      <div className={styles.tc}>
        <h3>Syarat dan Ketentuan</h3>
        <p>
          Syarat dan Ketentuan Umum PT Bank XYZ mengatur mengenai hubungan hukum
          antara PT XYZ dengan individu/korporasi yang menggunakan jasa/layanan
          PT Bank XYZ yang merupakan calon atau pemilik rekening di PT Bank XYZ
          sehubungan dengan segala produk, fasilitas dan/atau layanan yang
          tersedia bagi nasabah. Berikut ini merupakan poin - poin yang perlu
          diperhatikan sebelum melakukan pendaftaran ataupun transaksi.
        </p>
        <ol>
          <li>
            Hanya untuk nasabah perorangan Warga Negara Indonesia dan tidak
            berlaku Rekening Join
          </li>
          <li>Setoran awal pembukaan rekening Rp 20.000,-</li>
          <li>Setoran tunai selanjutnya Rp 10.000,-</li>
          <li>Saldo minimum rekening Rp 20.000,-</li>
          <li>Biaya apabila rekening tidak aktif Rp 2.000,- per bulan</li>
          <li>
            Apabila saldo rekening kurang dari Rp 20.000,- maka rekening akan
            ditutup secara otomatis oleh sistem
          </li>
          <li>Biaya penutupan rekening Rp 20.000,-</li>
          <li>
            Jumlah minimum penarikan di teller Rp 100.000,- kecuali pada saat
            nasabah ingin tutup rekening
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TnC;
