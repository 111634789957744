import { useEffect, useState } from "react";
import { Col, Row , Form, Container, Stack} from "react-bootstrap";
import { DOCS_OPEN_ACCOUNT } from "config/initial-state";
import StatusUpload from "components/molecules/status-bar/upload";
import { _HOST } from "config/constant/endpoints";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";

const FormDataDiri = () => {
    const { onConnect } = useSocket();
    const session = localStorage.getItem("pickup")!.split(",");
    const [data, setData] = useState([30, "","","","","", "", "", "","","", "", "","","","","","", "","","", "", "", "","", "","", "","","",false,"","","","","","","","", false])
    const [isDiffAddres,setIsDiffAddres] = useState(false)

    useEffect(()=>{
        const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg, "data")
      if(arg[0] == 30){
        console.log("sebelum", data)
        setData((curr) => arg)
        console.log("sesudah", data)

        setIsDiffAddres((curr) => arg[30])
        localStorage.setItem("data-diri",JSON.stringify(arg))
      }
    })
    return () => {
        socket.off("connect");
        socket.off("conferencing");
        socket.close();
      };
    }
    
    ,[])
    return (<Container>
        <Stack gap={3} className={styles.forms}>
            <StatusUpload status={DOCS_OPEN_ACCOUNT} />
            <Row>
                <Col>
                    <Row>
                        <Form.Group
                        as={Col}
                        xs={9}
                        >
                            <Form.Label>Nama Sesuai Indentitas</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="namaSesuaiIdentitas"
                                value={data[1].toString()}
                            />
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Gelar</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="gelar"
                            value={data[2].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Alamat Sesuai Identitas</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="alamatSesuaiIdentitas"
                            value={data[3].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>RT</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="RT"
                            value={data[4].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>RW</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="RW"
                            value={data[5].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Kelurahan/Desa</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="kelurahanDesa"
                            value={data[6].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Kecamatan</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="kecamatan"
                            value={data[7].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Kabupaten/Kota</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="kabupatenKota"
                            value={data[8].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Provinsi</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="provinsi"
                            value={data[9].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Kode Pos</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="kodePos"
                            value={data[10].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Kewarganegaraan</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="kewarganegaraan"
                            value={data[11].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Negara</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="negara"
                            value={data[12].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Jenis Identitas Lain</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="jenisIdentitasLain"
                            value={data[13].toString()}

                        />

                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Jumlah Tanggungan</Form.Label>
                        <Form.Control
                            required
                            name="jumlahTanggungan"
                            value={data[14].toString()}

                        >
                        </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Nama Suami/Istri/Orang Tua</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="namaSuamiIstriOrangtua"
                            value={data[15].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Jenis Kelamin</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="jenisKelamin"
                            value={data[16].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Agama</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="agama"
                            value={data[17].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Status Perkawinan</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="statusPerkawinan"
                            value={data[18].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>Pendidikan</Form.Label>
                        <Form.Control
                            required
                            name="pendidikan"
                            value={data[19].toString()}

                        >
                        </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                        as={Col}
                        xs={9}
                        className="mb-3"
                        controlId="formGroupAlamat"

                        >
                        <Form.Label>Nomor bukti Identitas</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="nomorBuktiIdentitas"
                            value={data[20].toString()}

                        />
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Jenis Bukti Identitas</Form.Label>
                        <Form.Control
                            required
                            name="jenisBuktiIdentitas"
                            value={data[21].toString()}

                        >
                            
                        </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Tanggal Terbit Indentitas</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="tanggalTerbitIdentitas"
                            value={data[22].toString()}

                        />
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Tanggal Berakhir Identitas</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="tanggalBerakhirIdentitas"
                            value={data[23].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Nama Ibu Kandung</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="namaIbuKandung"
                            value={data[24].toString()}

                        />
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Alamat Email</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="alamatEmail"
                            value={data[25].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Nomor Handphone</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="nomorHandphone"
                            value={data[26].toString()}

                        />
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Nomor Telepon</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="nomorTelepon"
                            value={data[27].toString()}

                        />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                        as={Col}
                        className="mb-4"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Nomor NPWP</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="nomorNPWP"
                            value={data[28].toString()}

                        />
                        </Form.Group>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Status Rumah</Form.Label>
                        <Form.Control
                            required
                            name="statusRumah"
                            value={data[29].toString()}

                        >
                        </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="formCheckPernyataan">
                            <Form.Label>
                            <b>Pernyataan</b>
                            </Form.Label>
                            <Form.Check
                            checked={isDiffAddres}
                            onChange={() => setIsDiffAddres(!isDiffAddres)}
                            className="text-muted"
                            label="Alamat jika berbeda dengan Identitas"
                            />
                        </Form.Group>
                    </Row>
                    <Row hidden={!isDiffAddres}>
                        <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formGroupAlamat"
                        >
                        <Form.Label>Alamat Sesuai Identitas</Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="alamatSesuaiIdentitas2"
                            value={data[31].toString()}

                        />
                        </Form.Group>
                    </Row>

                    <Row
                        // if isDiffAddres is true
                        // set hidden property
                        hidden={!isDiffAddres}
                    >
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label>RT</Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="RT2"
                            value={data[32].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label> RW </Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="RW2"
                            value={data[33].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label> Provinsi </Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="provinsi2"
                            value={data[34].toString()}

                        />
                            
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label> Kabupaten/Kota </Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="kabupatenKota2"
                            value={data[35].toString()}

                        />
                            
                        </Form.Group>
                    </Row>
                    <Row
                        // if isDiffAddres is true
                        // set hidden property
                        hidden={!isDiffAddres}
                    >
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label> Kecamatan </Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="kecamatan2"
                            value={data[36].toString()}

                        />
                        
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label> Kelurahan/Desa </Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="kelurahanDesa2"
                            value={data[37].toString()}

                        />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                        <Form.Label> Kode Pos </Form.Label>
                        <Form.Control
                            required={isDiffAddres}
                            type="text"
                            name="kodePos2"
                            value={data[38].toString()}
                            disabled
                        />
                        </Form.Group>
                        <Col></Col>
                    </Row>

                </Col>
            </Row>
        </Stack>
        
    </Container>)
}

export default FormDataDiri;