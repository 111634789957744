
import  Row  from "react-bootstrap/Row";
import  Card  from "react-bootstrap/Card";
import  Form  from "react-bootstrap/Form";
import  Container  from "react-bootstrap/Form";
import  Col  from "react-bootstrap/Col";
import { createElement, useEffect, useState } from "react";
import { _HOST } from "config/constant/endpoints";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { FaPlus } from "react-icons/fa";



const FormPembukaanAkun = () => {
    const { onConnect } = useSocket();
    const session = localStorage.getItem("pickup")!.split(",");
    const [data, setData] = useState([362,	false,	"line1",	"line 2",	"line 3", "nama",	"produk",	"pembukaan", "10000", "tandatangan", false, false])
    useEffect(()=>{
        const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg, "data")
      if(arg[0] === 362){
        console.log("sebelum", data)
        setData((curr) => arg)
        console.log("sesudah", data)

      }
    })
    return () => {
        socket.off("connect");
        socket.off("conferencing");
        socket.close();
      };
    }
    
    ,[])
    return (
        <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.upload}>
            <label htmlFor="">Unggah File</label>
            <div>{data[1] === "" ? createElement(FaPlus) : <p>{data[1]}</p>}</div>
          </div>
          <div className={styles.source}>
            <label htmlFor="">Rekening Sumber Dana</label>
            <div>
              <p>{data[2]}</p>
              <p>{data[3]}</p>
              <p>{data[4]}</p>
            </div>
          </div>
          <div className={styles.single}>
            <label htmlFor="">Nama Lengkap</label>
            <input type="text" value={data[5].toString()} disabled />
          </div>
          <div className={styles.single}>
            <label htmlFor="">Produk</label>
            <input type="text" value={data[6].toString()} disabled />
          </div>
          <div className={styles.single}>
            <label htmlFor="">Tanggal Pembukaan</label>
            <input type="text" value={data[7].toString()} disabled />
          </div>
          <div className={styles.single}>
            <label htmlFor="">Setoraan Awal</label>
            <input type="text" value={`Rp. ${Number(data[8]).toLocaleString("id")}`} disabled />
          </div>
          <div>
            <img 
              src={`data:image/png;base64,${localStorage.getItem("ttd")}`}
              alt="tanda tangan customer"
            />
          </div>
        </div>
      </div>
        
    )
}

export default FormPembukaanAkun