import { createElement, useCallback, useEffect, useState } from "react";
import { Tab } from "config/types";
import Account from "components/organisms/portfolio";
import Products from "pages/conversation/features/services";
import styles from "./index.module.scss";
import { AppDispatch } from "app/store";
import { useDispatch } from "react-redux";
import useSocket from "utilities/hooks/use-socket";
import { io } from "socket.io-client";
import { _HOST } from "config/constant/endpoints";
import { proceed } from "app/features/inbound";
import Tabbar from "components/molecules/tab";
import DonutChart from "components/organisms/donut-chart";
import { FaWallet } from "react-icons/fa";
import { endAndHome } from "components/molecules/navbar";
import axios from "axios";
import Loader from "components/atoms/loader";
import PortofolioNasabah from "./nasabah";
import { Card, Row, Stack } from "react-bootstrap";

const initialTab = [
  { label: "Portofolio", isActive: true },
  { label: "Layanan", isActive: false },
];
export interface nasabahInterface {
  id: number;
  facePath: string;
  idDips: string;
  isNasabah: boolean;
  name: string;
  created_at: string;
  updated_at: string;
}

const UserPortfolio = () => {
  // const [tab, setTab] = useState<Array<Tab>>(initialTab)
  // const content = tab.find(item => item.isActive)

  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");
  const [loading, setLoading] = useState(true);
  const [nasabah, setNasabah] = useState<nasabahInterface>();
  // const onTabs = useCallback((id: number) => {
  //   setTab(tab.map((item, i) => {
  //     if (id === i) {
  //       return {
  //         ...item,
  //         isActive: true
  //       }
  //     } else {
  //       return {
  //         ...item,
  //         isActive: false
  //       }
  //     }
  //   }))
  // }, [tab])

  useEffect(() => {
    setLoading(true);
    // axios.get(`${_HOST}/api/customer/portofolio/${session[1]}`).then(res => {
    //   if(res.data){
    //     localStorage.setItem("porto", JSON.stringify(res.data.data))
    //   }
    // })
    axios
      .get(`${_HOST}/api/customer/findbydipsid/${session[1]}`)
      .then((res) => {
        setNasabah((curr) => res.data);
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.customer}>
      <Stack gap={3}>
        <Tabbar tabs={initialTab} />
        {loading && <Loader />}
        {!loading && (
          <div className={styles.container} style={{ display: "block" }}>
            <div style={{ display: "block" }}>
              <h2>Portofolio {nasabah && nasabah.isNasabah ? nasabah.name:JSON.parse(localStorage.getItem("cif-data-diri")!)[1]}</h2>
              <DonutChart isRegistered={nasabah ? nasabah.isNasabah : false} />
            </div>
            {nasabah && !nasabah.isNasabah && (
              <>
              <Stack gap={2}>
              <h3>Informasi Portofolio</h3>
              <Card
             className="fw-normal p-2 mb-2"
             style={{ borderRadius: "5px", border: "1px solid black" }}
           >
            
             <Stack direction="horizontal" gap={2}>
              
               <div className="me-3">
                 <img
                   style={{
                     width: "30px",
                     height: "30px", //,
                     // position: "absolute",
                     // right: "9px",
                     // top: "6px",
                   }}
                   src={require("./nasabah/portofolioicon/tabungan.png")}
                 ></img>
               </div>
               <div>
                 <Row className="fw-bold" style={{ fontSize: "11px" }}>
                   Tabungan A - 123456789
                 </Row>
                 <Row className="" style={{ fontSize: "11px" }}>
                   Rp 0,00
                 </Row>
               </div>
             </Stack>
           </Card>
              </Stack>
              
              
              </>
             
            )}
            {nasabah && nasabah.isNasabah && <PortofolioNasabah />}
          </div>
        )}
      </Stack>
    </div>
  );
};

export default UserPortfolio;
