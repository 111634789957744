import { useEffect, useState } from "react";
import { Col, Container, Row, Form, Stack } from "react-bootstrap"
import { DOCS_OPEN_ACCOUNT } from "config/initial-state";
import StatusUpload from "components/molecules/status-bar/upload";
import { _HOST } from "config/constant/endpoints";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";

const FormDataPekerjaan = () => {
  const { onConnect } = useSocket();
    const session = localStorage.getItem("pickup")!.split(",");
    const [data, setData] = useState([31, "","","","","","", "", "", "","","", "", "","","","","","", "","", false])
    useEffect(()=>{
      const socket = io(_HOST);
  socket.on("connect", onConnect);
  socket.emit("call", "join", { room: session[1] });
  socket.on("conferencing", (...arg: any) => {
    console.log(arg, "data")
    if(arg[0] == 31){
      console.log("sebelum", data)
      setData((curr) => arg)
      console.log("sesudah", data)
      localStorage.setItem("data-pekerjaan",JSON.stringify(arg))
    }
  })
  return () => {
    socket.off("connect");
    socket.off("conferencing");
    socket.close();
  };
}

,[])
    return(
    <Container>
      <Stack gap={3} className={styles.forms}>
        <StatusUpload status={DOCS_OPEN_ACCOUNT} />

        <Row>
            <Col>
              <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Nama Perusahaan</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="alamatSesuaiIdentitas"
                      value={data[1].toString()}
                     
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Alamat Perusahaan</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="alamatSesuaiIdentitas"
                      value={data[2].toString()}
                     
                    />
                  </Form.Group>
                </Row>

                <Row
                // if isDiffAddres is true
                // set hidden property
                >
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>RT</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="RT"
                      value={data[3].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>RW</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="RW"
                      value={data[4].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Provinsi</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="provinsi"
                      value={data[5].toString()}

                    />
                      
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Kabupaten/Kota</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="kabupatenKota"
                      value={data[6].toString()}

                    />
                     
                  </Form.Group>
                </Row>
                <Row
                // if isDiffAddres is true
                // set hidden property
                >
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Kecamatan</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="kecamatan"
                      value={data[7].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label> Kelurahan/Desa </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="kelurahanDesa"
                      value={data[8].toString()}

                    />
                 
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Kode Pos</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="kodePos"
                      value={data[9].toString()}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Jumlah Karyawan</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="jumlahKaryawan"
                      value={data[10].toString()}

                    />
                      
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Nomor Telepon</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="nomorTelepon"
                      value={data[11].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Nomor Fax</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="nomorFax"
                      value={data[12].toString()}

                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Lama Bekerja</Form.Label>
                    <Form.Control
                     
                      required
                      type="text"
                      name="tahunLamaBekerja"
                      value={data[13].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3 mt-2">
                    <Form.Label> </Form.Label>
                    <Form.Control
                     
                      required
                      type="text"
                      name="bulanLamaBekerja"
                      value={data[14].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label> Total Bekerja </Form.Label>
                    <Form.Control
                     
                      required
                      type="text"
                      name="tahunTotalBekerja"
                      value={data[15].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3 mt-2">
                    <Form.Label> </Form.Label>
                    <Form.Control
                     
                      required
                      type="text"
                      name="bulanTotalBekerja"
                      value={data[16].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label> Pekerjaan </Form.Label>
                    <Form.Control
                     
                      required
                      type="text"
                      name="pekerjaan"
                      value={data[17].toString()}

                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label> Bidang Usaha </Form.Label>
                    <Form.Control
                     
                      required
                      type="text"
                      name="bidangUsaha"
                      value={data[18].toString()}

                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label> Jabatan </Form.Label>
                    <Form.Control
                     
                      required
                      type="text"
                      name="jabatan"
                      value={data[19].toString()}

                     
                    />
                      
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label> Bagian/Dept </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="bagian"
                      value={data[20].toString()}
                      
                    />
                  </Form.Group>
                </Row>
              
            </Col>
        </Row>
      </Stack>
        
    </Container>)
}

export default FormDataPekerjaan